import { BUSINESSES, BUSINESSES_GENERAL, FILE_TYPES, TICKET_STATUS } from '@/config'
import DialogClose from './components/DialogClose'

export default {
  components: {
    DialogClose
  },
  data () {
    return {
      mixinLoadings: {
        withdrawBtnLoading: false,
        reopenBtnLoading: false
      },
      mixinTicketTypes: [],
      mixinIsShowDialogConfirm: false,
      mixinCurTicketIds: [], // 待处理的id
      mixinTicketDetail: {},
      mixinCurConfirmType: ''
    }
  },
  computed: {
    mixinLang () {
      let { language } = this.$utils.getUserInfo()
      return language
    }
  },
  methods: {
    mixinGetTicketTypes () {
      this.$axios.get('/api/shipout-workorder/ticketTemplate/queryUserTicketTemplate', {
        params: {
          categoryType: this.$route.params.source === BUSINESSES.SHIPA2Z.VALUE ? BUSINESSES.SHIPOUT.VALUE : this.$route.params.source
        }
      }).then(res => {
        const all = []
        const _data = res.data.map(item => ({
          ...item,
          label: this.mixinLang === 'en' ? item.ticketTypeEn : item.ticketTypeCn,
          value: item.id
        }))
        this.mixinTicketTypes = all.concat(_data)
      }).catch(error => {
        console.error(error)
      })
    },
    mixinScrollTo (el, direction) {
      if (!(el instanceof HTMLElement)) return
      this.$nextTick(() => {
        el.scrollTop = direction === 'up' ? 0 : el.scrollHeight
      })
    },
    mixinFormatCategory (category) {
      category = JSON.parse(category)
      if (!Array.isArray(category)) return '--'
      if (category[0] === BUSINESSES_GENERAL.VALUE) return BUSINESSES_GENERAL.LABEL
      const result = []
      for (const key in BUSINESSES) {
        const v = BUSINESSES[key]
        if (category.includes(v.VALUE)) result.push(v.LABEL)
      }
      return result.join(',')
    },
    mixinGetFileIcon (type) {
      const key = Object.keys(FILE_TYPES).find(key => FILE_TYPES[key].VALUE === type)
      if (key) return FILE_TYPES[key].ICON
      return ''
    },
    mixinTicketAssign (assignType, ticketCaseIds) { // 分配（包含assign, transfer, reopen）
      this.mixinIsShowDialogAssign = true
      this.mixinCurAssignType = assignType
      this.mixinCurTicketIds = ticketCaseIds
    },
    mixinTicketConfirm (ticketCaseIds, type) { // 关闭
      this.mixinIsShowDialogConfirm = true
      this.mixinCurTicketIds = ticketCaseIds
      this.mixinCurConfirmType = type
    },
    mixinTicketRecieve (ticketCaseIds) { // 接收
      this.$axios.post('/api/shipout-workorder/admin/ticketCase/batchReceive', {
        ticketCaseIds
      })
        .then(res => {
          this.$message.success(this.$t('common.successTip'))
        })
        .catch(error => {
          console.error(error)
        })
    },
    mixinTicketReply () { // 回复

    },
    mixinGetActionMapTicketStatus () { // 获取操作名对工单状态的映射关系
      const result = {}
      Object.keys(TICKET_STATUS).forEach(key => {
        const { ACTIONS, VALUE } = TICKET_STATUS[key]
        ACTIONS.forEach(actionName => {
          if (!result[actionName]) result[actionName] = []
          if (!result[actionName].includes(VALUE)) {
            result[actionName].push(VALUE)
          }
        })
      })
      return result
    },
    mixinGetLabel (item) {
      return {
        en: item.labelEn,
        cn: item.labelCn
      }[this.mixinLang]
    }
  }
}

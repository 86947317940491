<template>
  <span class="c--tag">
    <span
      class="bg-color"
      :style="bgStyle"
    />
    <span :style="tagStyle">
      <slot>{{ curStatusItem.LABEL }}</slot>
    </span>
  </span>
</template>

<script>
import { TICKET_STATUS } from '@/config'
export default {
  name: 'StatusTag',
  props: {
    color: {
      type: String,
      default: ''
    },
    status: { // 传入正确的工单状态值会自动查找color,此时会忽略掉传入的color,未传入工单状态则可以当作普通的tag使用
      type: Number,
      default: -1
    },
    opacity: {
      type: Number,
      default: 0.2
    }
  },
  computed: {
    curStatusItem () {
      const key = Object.keys(TICKET_STATUS).find(key => TICKET_STATUS[key].VALUE === this.status)
      return TICKET_STATUS[key] || {}
    },
    statusColor () {
      return this.curStatusItem.COLOR
    },
    innerColor () {
      return this.statusColor || this.color
    },
    bgStyle () {
      return {
        background: this.innerColor,
        opacity: this.opacity
      }
    },
    tagStyle () {
      return {
        color: this.innerColor
      }
    }
  }
}
</script>

<style scoped lang="scss">
.c--tag{
  padding: 4px;
  border-radius: 4px;
  position: relative;
  font-size: 14px;
  .bg-color{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
  }
}
</style>

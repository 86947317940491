<template>
  <ez-dialog
    width="600px"
    title="Confirm"
    class="c--dialog-close"
    visible
    :before-close="handleCancel"
    :close-on-click-modal="false"
    append-to-body
    middle
  >
    <div class="title">
      <i class="ez-icon-warning waring-icon color-warning" />
      <span>Are you sure to close this support case?</span>
    </div>
    <div
      style="margin-top: 10px;padding-left: 20px"
    >
      <ez-input
        v-model="note"
        placeholder="Note"
        type="textarea"
        :rows="4"
      />
    </div>
    <template
      slot="footer"
    >
      <ez-button
        type="primary"
        size="small"
        :loading="isConfirmLoading"
        @click="handleConfirm"
      >
        Confirm
      </ez-button>
      <ez-button
        size="small"
        @click="handleCancel"
      >
        Cancel
      </ez-button>
    </template>
  </ez-dialog>
</template>

<script>
export default {
  props: {
    ticketCaseIds: {
      type: Array,
      default () {
        return []
      }
    },
    successCallBack: {
      type: Function,
      default: () => {}
    },
    confirmType: { // withdraw or reopen
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isConfirmLoading: false,
      note: null
    }
  },
  methods: {
    handleCancel () {
      this.$emit('close')
    },
    handleConfirm () {
      this.isConfirmLoading = true
      this.$axios.post('/api/shipout-workorder/admin/ticketCase/batchClose', {
        note: this.note,
        ticketCaseIds: this.ticketCaseIds
      })
        .then(() => {
          this.isConfirmLoading = false
          this.$message.success(this.$t('common.successTip'))
          this.$emit('close')
          this.successCallBack()
        }).catch(error => {
          console.error(error)
          this.isConfirmLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.c--dialog-close{
  .title{
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333;
    i{
      margin-right: 4px;
      font-size: 20px;
    }
  }
}
</style>

<template>
  <div class="c--actions-group">

      <!--      按钮风格-->
      <template v-if="isBtnStyle">
        <ez-button
          :key="item.label"
          type="primary"
          v-for="item in btnActions"
          @click="item.callBack">
          {{ item.label }}
        </ez-button>
      </template>

      <!--      文字按钮风格-->
      <template v-if="isTextBtnStyle">
        <RowActions
          :actions="rowActions"
          @onRowAction="onRowAction($event)"
        />
      </template>
  </div>
</template>

<script>
import { ACTIONS, TICKET_STATUS } from '@/config'
import RowActions from '@/views/components/RowActions'
export const SCENES = { // 按钮应用场景（列表页面，列表行操作， 详情操作）
  LIST: 'LIST',
  DETAIL: 'DETAIL',
  LIST_ROW: 'LIST_ROW'
}
export default {
  name: 'ActionsGroup',
  components: {
    RowActions
  },
  props: {
    scene: {
      type: String,
      default: SCENES.LIST
    },
    ticketDetail: {
      type: Object,
      default () {
        return {}
      }
    },
    callBack: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    isTextBtnStyle () {
      return [SCENES.LIST_ROW].includes(this.scene)
    },
    isBtnStyle () {
      return [SCENES.LIST, SCENES.DETAIL].includes(this.scene)
    },
    enableActions () { // 当前状态允许的操作
      const curKey = Object.keys(TICKET_STATUS).find(key => TICKET_STATUS[key].VALUE === this.ticketDetail.clientStatus)
      if (curKey) {
        const curItem = TICKET_STATUS[curKey]
        return curItem.ACTIONS
      } else {
        return []
      }
    },
    rowActions () { // 列表行的操作按钮
      const _actions = {
        default: [], more: []
      }
      _actions.default = this.enableActions.slice(0, 3)
      _actions.more = this.enableActions.slice(3)
      return {
        default: _actions.default.map(item => ({ label: item, event: item })),
        more: _actions.more.map(item => ({ label: item, event: item }))
      }
    },
    functionMap () { // 各个操作对应的执行函数
      return {
        [ACTIONS.REPLY]: this.handleReply,
        [ACTIONS.WITHDRAWN]: this.handleWithdraw,
        [ACTIONS.REOPEN]: this.handleReopen
      }
    },
    btnActions () { // 按钮风格的按钮组，用于详情或列表头部批量操作
      let _actions = this.enableActions
      if (this.scene === SCENES.DETAIL) { // 详情页面不需要reply, 即使该状态拥有这个操作
        _actions = _actions.filter(item => item !== ACTIONS.REPLY)
      }
      return _actions.map(actionName => ({
        label: actionName,
        callBack: this.functionMap[actionName]
      }))
    }
  },
  data () {
    return {
      SCENES
    }
  },
  methods: {
    onRowAction (event) {
      event = event.replace('on', '')
      this.functionMap[event] && this.functionMap[event]()
    },
    handleReply () {
      // this.$router.push({ name: 'ticket-detail', params: { ticketCaseId: this.ticketDetail.ticketCaseId } })
      const location = this.$router.resolve({ name: 'ticket-detail', params: { ticketCaseId: this.ticketDetail.ticketCaseId } })
      window.open(`${window.location.origin}/${location.href}`)
    },
    handleWithdraw () {
      this.$confirm(this.$t('common.cancelTip'), '', {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancelBtn'),
        type: 'warning'
      }).then(() => {
        this.$axios.post('/api/shipout-workorder/ticketcase/withDraw', {
          ticketCaseId: this.ticketDetail.ticketCaseId
        }).then(res => {
          this.$message.success(this.$t('common.successTip'))
          this.callBack()
        }).catch(error => {
          console.error(error)
        })
      })
    },
    handleReopen () {
      this.$prompt(this.$t('common.reopenTip'), '', {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancelBtn'),
        type: 'warning'
      }).then(({ value }) => {
        this.$axios.post('/api/shipout-workorder/ticketcase/reOpen', {
          note: value,
          ticketCaseIds: [this.ticketDetail.ticketCaseId]
        }).then(res => {
          this.$message.success(this.$t('common.successTip'))
          this.callBack()
        }).catch(error => {
          console.error(error)
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
